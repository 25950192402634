import { subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type UserStore = {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  pageSessionStartTimestamp: number;
  setPageSessionStartTimestamp: (value: number) => void;
};

const UserStore = createWithEqualityFn<UserStore>()(
  subscribeWithSelector((set) => ({
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn }),
    pageSessionStartTimestamp: 0,
    setPageSessionStartTimestamp: (pageSessionStartTimestamp: number) =>
      set({ pageSessionStartTimestamp }),
  })),
  shallow,
);

export default UserStore;
