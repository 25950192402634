import { enqueueOrRun } from '@frameio/segment-ot';

import getIntegrations from './getIntegrations';
import { getOneTrustConsent } from './getOneTrustConsent';

const trackEventSegment = (
  eventName: string,
  properties: Record<string, string>,
  callback?: () => void,
) => {
  // If we have a callback, create a safety mechanism to ensure it runs
  if (callback) {
    // Set a timeout to ensure callback runs even if enqueueOrRun fails
    const timeoutId = setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log('Segment tracking timeout - forcing callback execution');
      callback();
    }, 400);

    // Create a wrapped callback that clears the timeout
    const wrappedCallback = () => {
      clearTimeout(timeoutId);
      callback();
    };

    enqueueOrRun({
      event: () =>
        window.analytics &&
        window.analytics.track(
          eventName,
          properties,
          {
            context: getOneTrustConsent(),
            integrations: getIntegrations(),
          },
          wrappedCallback,
        ),
    });
  } else {
    // Original behavior when no callback is provided
    enqueueOrRun({
      event: () =>
        window.analytics &&
        window.analytics.track(eventName, properties, {
          context: getOneTrustConsent(),
          integrations: getIntegrations(),
        }),
    });
  }
};

export default trackEventSegment;
